<template>
  <div class="contents dashboard" v-loading="loading">
    <div class="title flexB">
      <h2>대시보드</h2>

      <div class="buttonWrap refresh">
        <p>{{ moment().format("YYYY.MM.DD HH:mm") }}</p>
        <!-- <button
          class="line"
          @click="getCount"
          :class="{ loading: inactive == true }"
        >
          새로고침
          <img src="@/assets/images/icon_svg/refresh.svg" alt="새로고침" />
        </button> -->
        <el-button :loading="inactive">새로고침</el-button>
      </div>
    </div>
    <div class="box one">
      <div class="contBox">
        <h2>가입자 수</h2>
        <div class="contWrap flex">
          <div class="cont">
            <div>
              <h4>
                전체 가입자
                <span class="material-icons-round"> group </span>
              </h4>
              <h3>{{ totalUser }}<span>명</span></h3>
            </div>
            <div class="btWrap flexB">
              <span class="increase crease">+10</span>
              <span class="period">from last month</span>
            </div>
          </div>
          <div class="cont">
            <h4>
              월별 가입자
              <span class="material-icons-round"> person_add_alt </span>
            </h4>
            <h3>{{ monthJoinUser }}<span>명</span></h3>
            <div class="btWrap flexB">
              <span class="decrease crease">-3</span>
              <span class="period">from last month</span>
            </div>
          </div>
          <div class="cont">
            <h4>
              일별 가입자
              <span class="material-icons-round"> person_add_alt </span>
            </h4>
            <h3>{{ dayJoinUser }}<span>명</span></h3>
            <div class="btWrap flexB">
              <span class="decrease crease">-3</span>
              <span class="period">from last month</span>
            </div>
          </div>
          <div class="cont">
            <h4>
              탈퇴 회원
              <span class="material-icons-round"> person_remove </span>
            </h4>
            <h3>{{ dropUser }}<span>명</span></h3>
            <div class="btWrap flexB">
              <span class="increase crease">+10</span>
              <span class="period">from last month</span>
            </div>
          </div>
        </div>
      </div>
      <div class="contBox">
        <h2>게시글 수</h2>
        <div class="contWrap flex">
          <div class="cont">
            <h4>
              전체 등록된 게시글 수
              <span class="material-icons-round"> post_add </span>
            </h4>
            <h3>{{ totalPost }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4>
              이번달 등록된 게시글 수<span class="material-icons-round">
                post_add
              </span>
            </h4>
            <h3>{{ monthPost }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4>
              신규 등록된 게시글 수<span class="material-icons-round">
                post_add
              </span>
            </h4>
            <h3>{{ dayPost }}<span>개</span></h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { format } from "@/mixins/format";
import { fetchDashboard } from "@/api/admin";

export default {
  mixins: [format],
  name: "dashboard",
  data() {
    return {
      moment: moment,
      dashboard: [],
      barData: [],
      category: [],
      loading: false,
      inactive: false,
      totalUser: "",
      monthJoinUser: "",
      dayJoinUser: "",
      dropUser: "",
      totalPost: "",
      monthPost: "",
      dayPost: "",
      totalArticle: "",
      monthArticle: "",
      dayArticle: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "0");
  },
  mounted() {
    // this.loading = true;
    this.getCount();
    this.yearOption =
      moment().format("YYYY") - 2023 == 0
        ? 1
        : moment().format("YYYY") - 2023 + 1;
  },
  methods: {
    getCount() {
      this.inactive = true;
      fetchDashboard().then((res) => {
        if (res.data.status == 200) {
          this.totalUser = res.data.totalUser;
          this.monthJoinUser = res.data.monthJoinUser;
          this.dayJoinUser = res.data.dayJoinUser;
          this.dropUser = res.data.dropUser;
          this.totalPost = res.data.totalPost;
          this.monthPost = res.data.monthPost;
          this.dayPost = res.data.dayPost;
          this.totalArticle = res.data.totalArticle;
          this.monthArticle = res.data.monthArticle;
          this.dayArticle = res.data.dayArticle;
          this.loading = false;
          this.inactive = false;
        }
      });
    },
  },
};
</script>
